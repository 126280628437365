import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import PartnersCarousel from '@/components/PartnersCarousel'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

export const Partners: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)

  useEffect(() => {
    setAnimationStart(true)
  }, [])

  return (
    <Waypoint>
      <section
        className={clsx(styles['section'], className, {
          [styles['section_animation-start']]: animationStart
        })}
      >
        <div className={styles['section__content']}>
          <PartnersCarousel />
        </div>
      </section>
    </Waypoint>
  )
}
