import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import clsx from 'clsx'
import { FC, useRef } from 'react'

import Animation from '@/components/Animation'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import Apple from '../../../public/images/partners/apple.svg'
import Beeline from '../../../public/images/partners/beeline.svg'
import Dzen from '../../../public/images/partners/dzen.svg'
import GoogleAds from '../../../public/images/partners/google-ads.svg'
import Mgid from '../../../public/images/partners/mgid.svg'
import MyTarget from '../../../public/images/partners/my-target.svg'
import Pornhub from '../../../public/images/partners/pornhub.svg'
import Telegram from '../../../public/images/partners/telegram.svg'
import TikTok from '../../../public/images/partners/tik-tok.svg'
import VkAds from '../../../public/images/partners/vk-ads.svg'
import Vk from '../../../public/images/partners/vk.svg'
import YaBusiness from '../../../public/images/partners/ya-business.svg'
import Yahoo from '../../../public/images/partners/yahoo.svg'
import Yandex from '../../../public/images/partners/yandex.svg'

import styles from './Partners.module.scss'

const PARTNERS = [
  {
    name: 'yandex',
    Icon: Yandex
  },
  {
    name: 'tik-tok',
    Icon: TikTok
  },
  {
    name: 'my-target',
    Icon: MyTarget
  },
  {
    name: 'telegram',
    Icon: Telegram
  },
  {
    name: 'ya-business',
    Icon: YaBusiness
  },
  {
    name: 'vk',
    Icon: Vk
  },
  {
    name: 'pornhub',
    Icon: Pornhub
  },
  {
    name: 'google-ads',
    Icon: GoogleAds
  },
  {
    name: 'vk-ads',
    Icon: VkAds
  },
  {
    name: 'beeline',
    Icon: Beeline
  },
  {
    name: 'dzen',
    Icon: Dzen
  },
  {
    name: 'apple',
    Icon: Apple
  },
  {
    name: 'mgid',
    Icon: Mgid
  },
  {
    name: 'yahoo',
    Icon: Yahoo
  }
]

const OPTIONS: Options = {
  autoHeight: true,
  autoWidth: true,
  arrows: false,
  classes: {
    pagination: `splide__pagination ${styles['partners__splide-pagination']}`,
    page: `splide__pagination__page  ${styles['partners__splide-page']}`
  },
  pagination: false,
  mediaQuery: 'min',
  type: 'loop',
  drag: false,
  focus: 'center',
  autoScroll: {
    speed: 1,
    pauseOnHover: false
  },
  breakpoints: {
    320: {
      gap: '5.5rem'
    },
    1024: {
      gap: '6.5rem'
    },
    1680: {
      gap: '11rem'
    }
  }
}

const PartnersCarousel: FC<DefaultComponentProps> = ({ className = '' }) => {
  const splideRef = useRef<Splide>(null)

  return (
    <div className={clsx(styles['partners'], className)}>
      <Animation
        lang={false}
        type={'pacman'}
        className={styles['partners__pacman']}
      />

      <Splide
        ref={splideRef}
        hasTrack={false}
        options={OPTIONS}
        extensions={{ AutoScroll }}
      >
        <SplideTrack>
          {PARTNERS.map(({ name, Icon }, index) => (
            <SplideSlide
              role={'section'}
              key={name + index}
              className={styles['partners__item']}
            >
              <div className={styles['partners__fig']}>
                <Icon />
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  )
}
export default PartnersCarousel
