import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import DefaultComponentProps from '@/types/DefaultComponentProps'

interface AnimationProps {
  type?: number | string
  loop?: boolean
  autoplay?: boolean
  isClickToPauseDisabled?: boolean
  animationPlay?: boolean | null
  width?: string
  height?: string
  lang?: boolean
}

const Animation: FC<AnimationProps & DefaultComponentProps> = ({
  type,
  className = '',
  loop = true,
  autoplay = true,
  isClickToPauseDisabled = true,
  animationPlay = null,
  width = '100%',
  height = '100%',
  lang = true
}) => {
  const {
    i18n: { language }
  } = useTranslation()

  const [animation, setAnimation] = useState(null)
  const [isPaused, setPaused] = useState(false)

  useEffect(() => {
    if (animationPlay === null) {
      return
    }
    setPaused(!animationPlay)
  }, [animationPlay])

  useEffect(() => {
    if (!type || !language) {
      return
    }

    async function fetchAnimation() {
      const data = await import(
        `../../animations${lang ? '/' + language : ''}/${type}.json`
      )
      setAnimation(data)
    }

    fetchAnimation()
  }, [type, language, lang])

  if (!animation) {
    return null
  }

  return (
    <div className={className}>
      <Lottie
        options={{
          loop,
          autoplay,
          animationData: animation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        isPaused={isPaused}
        width={width}
        height={height}
        isClickToPauseDisabled={isClickToPauseDisabled}
      />
    </div>
  )
}
export default Animation
